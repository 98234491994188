import { Fragment, useState, useLayoutEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import ReactMarkdown from 'react-markdown'
import axios from 'axios';

import AlertImage from '../assets/img/alert.svg'

import Map from '../components/map';
import Endpoints from '../lib/endpoints';
import Layout from '../components/layout'
import { windDirection } from '../components/graphs/WindDirections';
import classNames from 'classnames';
import DashboardTabs from '../components/tabs';
import { convertMStoKmH } from '../lib/utils';
import moment from 'moment';

import alertsJSON from "../assets/json/alerts.json"
import coursesJSON from "../assets/json/courses.json"
import currentJSON from "../assets/json/current.json"
import reportsJSON from "../assets/json/reports.json"

const tabs = [
    { name: 'Graphics and Data' },
    { name: 'Reports' },
]

export default function Dashboard() {
    const [reports, setReports] = useState([])
    const [lastReport, setLastReport] = useState({})

    const [openAlerts, setOpenAlerts] = useState(false)
    const [openReports, setOpenReports] = useState(false)

    const [courses, setCourses] = useState([])
    const [currentWeather, setCurrentWeather] = useState()
    const [alerts, setAlerts] = useState([])

    const [selectedCourse, setSelectedCourse] = useState()
    const [selectedPoint, setSelectedPoint] = useState(0)

    const [selectedTab, setSelectedTab] = useState(0)

    const onClickMarker = (marker) => {
        for (var i = 0; i < selectedCourse.points.length; i++) {
            if (marker.latlng.lat === selectedCourse.points[i].lat && marker.latlng.lng === selectedCourse.points[i].lon) {
                setSelectedPoint(i);
                return
            }
        }
    }

    useLayoutEffect(() => {
        /*
        axios.get(Endpoints.reports + `?_sort=creationDate:DESC`)
            .then((response) => {
                setReports(response.data)
                setLastReport(response.data[0])
            })
            .catch((error) => console.error(error))

        axios.get(Endpoints.courses)
            .then((response) => {
                var newResponse = response.data
                newResponse.sort((a, b) => a.id < b.id)
                setCourses(newResponse)
                setSelectedCourse(newResponse[0])
            })
            .catch((error) => console.error(error))
            */
        setReports(reportsJSON)
        setLastReport(reportsJSON[0])
        setCourses(coursesJSON)
        setSelectedCourse(coursesJSON[0])
    }, [])

    useLayoutEffect(() => {
        if (selectedCourse) {
            /*
            // CURRENT
            axios.get(Endpoints.getWeatherURLFor(selectedCourse.id, null, null, true))
                .then((response) => setCurrentWeather(response.data))
                .catch((error) => console.error(error))
            // ALERTS
            axios.get(Endpoints.getWeatherURLFor(selectedCourse.id, null, true, null))
                .then((response) => setAlerts(response.data.points))
                .catch((error) => console.error(error))
                */
            setCurrentWeather(currentJSON)
            setAlerts(alertsJSON)
        }
    }, [alerts, selectedCourse])

    return <Layout>
        <div className="flex flex-row h-full w-full">
            <div className="relative w-1/3">
                <div className="w-1/4 absolute right-4 top-2 z-50">
                    <select
                        id="course"
                        name="course"
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-azure-500 focus:border-azure-500 sm:text-sm rounded-md"
                        defaultValue={selectedCourse}
                        onChange={(e) => {
                            const course = courses.filter((c) => c.id === e.target.value)[0]
                            setSelectedCourse(course)
                            setSelectedPoint(0)
                        }}
                    >
                        {courses.map((course) => <option key={course.id} value={course.id}>{course.id}</option>)}
                    </select>
                </div>
                {
                    selectedCourse && <Map center={[18.536374, 98.546994]} markers={selectedCourse.points} onClick={onClickMarker} active={selectedCourse.points[selectedPoint]} course={selectedCourse} markerAlerts={alerts.length > 0 ? alerts[selectedPoint].alerts : null} />
                }
            </div>
            <div className="flex flex-col overflow-y-scroll mt-2 space-y-2 w-2/3">
                {
                    (selectedCourse && currentWeather) && <div className="bg-white border-2 border-azure-700 sm:rounded-md mx-2">
                        <div className="p-4 flex flex-col">
                            <div className="flex flex-row place-items-center justify-between">
                                <h2 className="text-xl font-semibold">{selectedCourse.points[selectedPoint].name}</h2>
                                <div className="flex flex-col">
                                    <p className="text-sm text-right font-semibold">Last data update: {moment(moment.unix(currentWeather.points[selectedPoint].current_weather.dt)).tz('Asia/Bangkok').format("DD/MM (HH")} h) UTC+7</p>
                                    <p className="text-xs text-right">Data: NOAA GFS (0.25 AND 0.5) – NOAA CFS – ECMWF ERA</p>
                                </div>
                            </div>
                            <div className="flex flex-row place-items-center justify-around">
                                <div className="flex flex-col place-items-center">
                                    <img src={`http://openweathermap.org/img/wn/${currentWeather.points[selectedPoint].current_weather.weather[0].icon}@2x.png`} alt={currentWeather.points[0].current_weather.weather[0].description} width="80" height="80" />
                                    <p className="text-xl text-center">{currentWeather.points[selectedPoint].current_weather.weather[0].description}</p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="text-5xl font-semibold">{`${currentWeather.points[selectedPoint].current_weather.main.temp.toFixed(0)} °C`}</p>
                                    <p className="text-xl text-center">Humidity: {currentWeather.points[selectedPoint].current_weather.main.humidity} %</p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="text-md text-center">{`${currentWeather.points[selectedPoint].current_weather.wind.speed}`} m/s</p>
                                    <p className="text-5xl font-semibold">{`${convertMStoKmH(currentWeather.points[selectedPoint].current_weather.wind.speed)}`} km/h</p>
                                    <p className="text-xl text-center">{currentWeather.points[selectedPoint].current_weather.wind.deg} ° {windDirection(currentWeather.points[selectedPoint].current_weather.wind.deg)}</p>
                                </div>
                                {
                                    ((alerts.length > 0 && alerts[selectedPoint].alerts) && <button type="button"
                                        className="rounded-full h-24 w-24 flex items-center justify-center border border-azure-700 shadow-sm text-sm font-medium text-white bg-white hover:bg-azure-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-azure-500"
                                        onClick={() => setOpenAlerts(true)}
                                    >
                                        <img src={AlertImage} alt="alert" width="50" height="50" />
                                    </button>)
                                }
                            </div>
                        </div>
                    </div>
                }
                <div className="bg-white border-2 border-azure-700 sm:rounded-md mx-2 flex flex-col mb-5">
                    <div>
                        <div className="sm:hidden">
                            <label htmlFor="tabs" className="sr-only">
                                Select a tab
                            </label>
                            <select
                                id="tabs"
                                name="tabs"
                                className="block w-full focus:ring-azure-500 focus:border-azure-500 border-gray-300 rounded-md"
                                defaultValue={tabs[selectedTab].name}
                                onChange={(e) => setSelectedTab(e.target.value)}
                            >
                                {tabs.map((tab, index) => (
                                    <option key={tab.name} value={index}>{tab.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="hidden sm:block">
                            <div className="border-b border-gray-200">
                                <nav className="-mb-px flex" aria-label="Tabs">
                                    {tabs.map((tab, index) => (
                                        <button
                                            key={tab.name}
                                            className={classNames(
                                                index === selectedTab
                                                    ? 'border-azure-500 text-azure-600'
                                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                                'w-full py-4 px-1 text-center border-b-2 font-medium text-sm'
                                            )}
                                            aria-current={index === selectedTab ? 'page' : undefined}
                                            onClick={() => setSelectedTab(index)}
                                        >
                                            {tab.name}
                                        </button>
                                    ))}
                                </nav>
                            </div>
                        </div>
                    </div>
                    <DashboardTabs reports={reports} lastReport={lastReport} selectedTab={selectedTab} setOpenReports={setOpenReports} selectedCourse={selectedCourse} selectedPoint={selectedPoint} />
                </div>
            </div>
        </div>
        {/* Alerts slideview */}
        <Transition.Root show={openAlerts} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={setOpenAlerts}>
                <div className="absolute inset-0 overflow-hidden">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className="w-screen max-w-xl">
                                <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                                    <div className="px-4 sm:px-6">
                                        <div className="flex items-start justify-between">
                                            <Dialog.Title className="text-xl font-medium text-gray-900">Alerts</Dialog.Title>
                                            <div className="ml-3 h-7 flex items-center">
                                                <button
                                                    type="button"
                                                    className="bg-white rounded-xl text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-azure-500"
                                                    onClick={() => setOpenAlerts(false)}
                                                >
                                                    <span className="sr-only">Close panel</span>
                                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-6 relative flex-1 px-4 sm:px-6">
                                        {
                                            ((alerts.length > 0 && alerts[selectedPoint].alerts) &&
                                                <div className="flex flex-col">
                                                    {alerts[selectedPoint].alerts.map((alert) => {
                                                        const startDate = moment(moment.unix(alert.start)).tz('Asia/Bangkok').format("DD-MM-YYYY");
                                                        const endDate = moment(moment.unix(alert.end)).tz('Asia/Bangkok').format("DD-MM-YYYY");
                                                        return <div key={alert.title}>
                                                            <a href={alert.href} className="block mt-4">
                                                                <p className="text-xl font-semibold text-gray-900">{alert.event}</p>
                                                                <div className="my-3 flex items-center">
                                                                    <div className="flex-shrink-0">
                                                                        <span className="sr-only">{alert.sender_name}</span>
                                                                    </div>
                                                                    <div className="ml-3">
                                                                        <p className="text-sm font-medium text-gray-900">
                                                                            {alert.sender_name}
                                                                        </p>
                                                                        <div className="flex space-x-1 text-sm text-gray-500">
                                                                            <time dateTime={startDate}>{startDate}</time>
                                                                            <span aria-hidden="true">&middot;</span>
                                                                            <time dateTime={endDate}>{endDate}</time>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    {
                                                                        alert.tags.map((tag) => (
                                                                            <span key={tag}
                                                                                className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-yellow-500">
                                                                                {tag}
                                                                            </span>
                                                                        ))
                                                                    }
                                                                </div>
                                                                <p className="mt-3 text-base text-gray-500">{alert.description}</p>
                                                            </a>
                                                            <hr />
                                                        </div>
                                                    })}
                                                </div>)
                                        }
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
        {/* Reports archive */}
        <Transition.Root show={openReports} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={setOpenReports}>
                <div className="absolute inset-0 overflow-hidden">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className="w-screen max-w-xl">
                                <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                                    <div className="px-4 sm:px-6">
                                        <div className="flex items-start justify-between">
                                            <Dialog.Title className="text-xl font-medium text-gray-900">Reports archive</Dialog.Title>
                                            <div className="ml-3 h-7 flex items-center">
                                                <button
                                                    type="button"
                                                    className="bg-white rounded-xl text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-azure-500"
                                                    onClick={() => setOpenReports(false)}
                                                >
                                                    <span className="sr-only">Close panel</span>
                                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-6 relative flex-1 px-4 sm:px-6">
                                        {
                                            reports && reports.map((report) => (
                                                <div key={report.id} className="py-5">
                                                    <div className="relative focus-within:ring-2 focus-within:ring-azure-500">
                                                        <h3 className="text-xl font-semibold text-gray-800">
                                                            Report for {`${moment(report.creationDate).format("DD-MM-YYYY")}`}
                                                        </h3>
                                                        <ReactMarkdown className="mt-1 text-sm text-gray-600 line-clamp-2">{report.text}</ReactMarkdown>
                                                        <div className="flex flex-col lg:flex-row justify-around place-items-center mt-5">
                                                            {
                                                                report.PDF.map((pdf) => (
                                                                    <a key={pdf.file.name} href={pdf.file.url} download={pdf.file.name}>
                                                                        <button type="button"
                                                                            className="m-3 inline-flex items-center px-4 py-2 border border-transparent rounded-xl shadow-sm text-sm font-medium text-white bg-azure-600 hover:bg-azure-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-azure-500"
                                                                        >
                                                                            Download PDF for {pdf.country}
                                                                        </button>
                                                                    </a>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    </Layout>
}