export const windDirection = (windDeg) => {
    if (windDeg >= 22.5 && windDeg < 67.5) return "NE"
    else if (windDeg >= 67.5 && windDeg < 112.5) return "E"
    else if (windDeg >= 112.5 && windDeg < 157.5) return "SE"
    else if (windDeg >= 157.5 && windDeg < 202.5) return "S"
    else if (windDeg >= 202.5 && windDeg < 247.5) return "SW"
    else if (windDeg >= 247.5 && windDeg < 292.5) return "W"
    else if (windDeg >= 292.5 && windDeg < 337.5) return "NW"
    else return "N"
};

export const getArrow = (windDeg) => {
    var windImage = new Image(20, 20);

    if (windDeg >= 22.5 && windDeg < 67.5) windImage.src = '/wind/wi-direction-down-left.svg' // NE
    else if (windDeg >= 67.5 && windDeg < 112.5) windImage.src = '/wind/wi-direction-left.svg' // E
    else if (windDeg >= 112.5 && windDeg < 157.5) windImage.src = '/wind/wi-direction-up-left.svg' // SE
    else if (windDeg >= 157.5 && windDeg < 202.5) windImage.src = '/wind/wi-direction-up.svg' // S
    else if (windDeg >= 202.5 && windDeg < 247.5) windImage.src = '/wind/wi-direction-up-right.svg' // SW
    else if (windDeg >= 247.5 && windDeg < 292.5) windImage.src = '/wind/wi-direction-right.svg' // W
    else if (windDeg >= 292.5 && windDeg < 337.5) windImage.src = '/wind/wi-direction-down-right.svg' // NW
    else windImage.src = '/wind/wi-direction-down.svg' // N

    return windImage
}