import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { init } from "./lib/ga";
import Dashboard from "./pages/dashboard";

function App() {
  
  useEffect(() => {
    init(process.env.REACT_APP_GA4);
  }, [])

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Dashboard />} />
      </Routes>
    </div>
  );
}

export default App;
