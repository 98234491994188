import moment from "moment"
import ReactMarkdown from "react-markdown"
import DataTabs from "./data"

export default function DashboardTabs({ reports, lastReport, selectedTab, setOpenReports, selectedCourse, selectedPoint }) {
    switch (selectedTab) {
        case 0:
            return <div className="px-4 py-2 p-4">
                <DataTabs selectedCourse={selectedCourse} selectedPoint={selectedPoint} />
            </div>
        case 1:
            if (reports.length > 0) return <div className="px-4 py-2 p-4">
                <div className="flex flex-row justify-between place-items-center py-2">
                    <h2 className="text-2xl font-semibold">Latest report</h2>
                    <button onClick={() => setOpenReports(true)}><p className="underline hover:text-azure-300">Reports archive</p></button>
                </div>
                <div key={lastReport.id} className="list-none">
                    <div className="relative focus-within:ring-2 focus-within:ring-azure-500">
                        <h3 className="text-xl font-semibold text-gray-800">
                            Report for {`${moment(lastReport.creationDate).format("DD-MM-YYYY")}`}
                        </h3>
                        <ReactMarkdown className="mt-1 text-sm text-gray-600 line-clamp-4 lg:line-clamp-1">{lastReport.text}</ReactMarkdown>
                        <div className="flex flex-col lg:flex-row justify-around place-items-center mt-1">
                            {
                                lastReport.PDF && lastReport.PDF.map((pdf) => (
                                    <a key={pdf.file.name} href={pdf.file.url} download={pdf.file.name}>
                                        <button type="button"
                                            className="m-3 inline-flex items-center px-4 py-2 border border-transparent rounded-xl shadow-sm text-sm font-medium text-white bg-azure-600 hover:bg-azure-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-azure-500"
                                        >
                                            Download PDF for {pdf.country}
                                        </button>
                                    </a>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            else return <p>There is no report yet</p>
        default:
            return <p>An error has ocurred</p>
    }
}