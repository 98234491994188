import MaladetaLogo from '../assets/img/logo+name_white.svg'
import UTMBLogo from '../assets/img/UTMB.svg'
import ThaiUTMBLogo from '../assets/img/thai_utmb_logo.svg'

const navigation = []

export default function Header() {
    return <header className="bg-azure-600">
        <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
            <div className="w-full py-6 flex items-center justify-between border-b border-azure-500 lg:border-none">
                <div className="flex items-center">
                    <a href="https://thailandbyutmb.com/">
                        <span className="sr-only">UTMB</span>
                        <img
                            className="h-12 w-auto"
                            src={ThaiUTMBLogo}
                            alt=""
                        />
                    </a>
                    <div className="hidden ml-10 space-x-8 lg:block">
                        {navigation.map((link) => (
                            <a key={link.name} href={link.href} className="text-base font-medium text-white hover:text-azure-50">
                                {link.name}
                            </a>
                        ))}
                    </div>
                </div>
                <div className="ml-10 space-x-4 flex flex-row">
                    <a href="https://utmb.world/es">
                        <span className="sr-only">UTMB</span>
                        <img
                            className="h-12 w-auto"
                            src={UTMBLogo}
                            alt=""
                        />
                    </a>
                    <a href="https://maladetastudio.com">
                        <span className="sr-only">Maladeta Studio</span>
                        <img
                            className="h-12 w-auto"
                            src={MaladetaLogo}
                            alt=""
                        />
                    </a>
                </div>
            </div>
            <div className="py-4 flex flex-wrap justify-center space-x-6 lg:hidden">
                {navigation.map((link) => (
                    <a key={link.name} href={link.href} className="text-base font-medium text-white hover:text-azure-50">
                        {link.name}
                    </a>
                ))}
            </div>
        </nav>
    </header>
}