export default class Endpoints {
    static strapiURL = process.env.REACT_APP_STRAPI_URL;
    static reports = Endpoints.strapiURL + "/reports"

    static serverURL = process.env.REACT_APP_SERVER_URL;
    static courses = Endpoints.serverURL + "/courses"

    /* Returns the most suitable url for each day or alerts, either days, alerts or current must be set */
    static getWeatherURLFor(id: String, days?: number, alerts?: boolean, current?: boolean): string {
        if (days || alerts || current) {
            if (days) return `${this.courses}/${id}/${days}days`
            if (alerts) return `${this.courses}/${id}/alerts`
            if (current) return `${this.courses}/${id}/current`
        } else {
            return ""
        }
        return ""
    }

    static forceUpdate = "/force-update"
}