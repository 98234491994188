import { MapContainer, TileLayer, Marker, GeoJSON } from 'react-leaflet'

import inthanon5 from '../assets/files/inthanon5.geojson.json';
import inthanon3 from '../assets/files/inthanon3.geojson.json';
import inthanon1 from '../assets/files/inthanon1.geojson.json';
import inthanon6 from '../assets/files/inthanon6.geojson.json';

import 'leaflet/dist/leaflet.css'
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css'
import "leaflet-defaulticon-compatibility";
import { useEffect, useState } from 'react';

export default function Map({ center, markers, onClick, active, course, markerAlerts }) {
    const [zoom, setZoom] = useState(12)
    
    function getRoute(id) {
        switch (id) {
            case "inthanon5":
                return inthanon5
            case "inthanon3":
                return inthanon3
            case "inthanon1":
                return inthanon1
            case "inthanon6":
                return inthanon6
            default:
                return inthanon5
        }
    }

    useEffect(() => {
        switch (course.id) {
            case "inthanon5":
                setZoom(12)
                break
            case "inthanon3":
                setZoom(12)
                break
            case "inthanon1":
                setZoom(12)
                break
            case "inthanon6":
                setZoom(12)
                break
            default:
                setZoom(12)
        }
    }, [course])

    return (
        <MapContainer key={zoom} className="z-10" center={center} zoom={zoom} scrollWheelZoom={false} style={{ height: "100%", width: "100%" }}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
            />
            <GeoJSON key={course.id} data={getRoute(course.id)} style={() => {
                return { color: "#F23E2D" };
            }} />

            {
                markers.map((marker, i) => {
                    const redIcon = new window.L.Icon({
                        iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
                        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
                        iconSize: [25, 41],
                        iconAnchor: [12, 41],
                        popupAnchor: [1, -34],
                        shadowSize: [41, 41]
                    });

                    const blueIcon = new window.L.Icon({
                        iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
                        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
                        iconSize: [25, 41],
                        iconAnchor: [12, 41],
                        popupAnchor: [1, -34],
                        shadowSize: [41, 41]
                    });

                    const blackIcon = new window.L.Icon({
                        iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-black.png',
                        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
                        iconSize: [25, 41],
                        iconAnchor: [12, 41],
                        popupAnchor: [1, -34],
                        shadowSize: [41, 41]
                    });

                    var icon = redIcon;


                    if ((active.lat === marker.lat) && (active.lon === marker.lon)) icon = redIcon;
                    else {
                        if (markerAlerts) icon = blackIcon;
                        else icon = blueIcon;
                    }


                    return (<Marker
                        id={i}
                        key={marker.lat + " " + marker.lon + " " + marker.id}
                        position={[marker.lat, marker.lon]}
                        animate={true}
                        eventHandlers={{
                            click: onClick,
                        }}
                        icon={icon}
                    />)
                })
            }


        </MapContainer>
    )
}
