import axios from "axios"
import { useLayoutEffect, useState } from "react"
import Endpoints from "../lib/endpoints"
import { getArrow, windDirection } from './graphs/WindDirections';

import { Chart as ChartJS, registerables } from 'chart.js';

import { Chart, Line } from 'react-chartjs-2';
import { convertMStoKmH } from "../lib/utils";
import moment from "moment";
import 'moment-timezone';

import days2JSON from "../assets/json/2days.json"
import days5JSON from "../assets/json/5days.json"
import days12JSON from "../assets/json/12days.json"

ChartJS.register(...registerables);

export default function DataTabs({ selectedTab, selectedCourse, selectedPoint }) {
    const [days2Data, set2DaysData] = useState()
    const [days5Data, set5DaysData] = useState()
    const [days12Data, set12DaysData] = useState()

    const [labels, setLabels] = useState(['January', 'February', 'March', 'April', 'May', 'June', 'July'])
    const [pointsStyle, setPointsStyle] = useState([])

    const options = {
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        stacked: false,
        plugins: {},
        scales: {
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                title: {
                    display: true,
                    text: '°C',
                    color: 'rgb(255, 99, 132)'
                }
            },
            y1: {
                type: 'linear',
                display: true,
                position: 'right',
                grid: {
                    drawOnChartArea: false,
                },
                title: {
                    display: true,
                    text: '%',
                    color: 'rgb(0, 0, 0)',
                }
            },
        },
    };

    const options2 = {
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        stacked: false,
        plugins: {
            tooltip: {
                backgroundColor: 'rgba(0, 0, 0, 0.4)',
                usePointStyle: true,
            }
        },
        scales: {
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                title: {
                    display: true,
                    text: 'km/h',
                    color: 'rgb(0, 0, 0)',
                }
            },
            y1: {
                type: 'linear',
                display: true,
                position: 'right',
                grid: {
                    drawOnChartArea: false,
                },
                title: {
                    display: true,
                    text: 'mm/3h',
                    color: 'rgb(15, 91, 255)',
                }
            },
        },
    };

    const data = {
        labels,
        datasets: [
            {
                label: 'Temperature (°C)',
                data: days5Data ? days5Data.points[selectedPoint]["5days"].list.map((day) => day.main.temp) : [],
                borderColor: 'rgb(255, 99, 132)',
                borderWidth: 1,
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                yAxisID: 'y',
            },
            {
                label: 'Humidity (%)',
                data: days5Data ? days5Data.points[selectedPoint]["5days"].list.map((day) => day.main.humidity) : [],
                borderColor: 'rgb(0, 0, 0)',
                borderWidth: 1,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                yAxisID: 'y1',
            },
        ],
    };

    const data2 = {
        labels,
        datasets: [
            {
                type: 'line',
                label: 'Wind speed (km/h)',
                borderWidth: 1,
                borderColor: 'rgb(0, 0, 0)',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                fill: false,
                data: days5Data ? days5Data.points[selectedPoint]["5days"].list.map((day) => convertMStoKmH(day.wind.speed)) : [],
                yAxisID: 'y',
                pointStyle: pointsStyle,
            },
            {
                type: 'bar',
                label: 'Rain (mm/3h)',
                backgroundColor: 'rgb(15, 91, 255, 0.5)',
                data: days5Data ? days5Data.points[selectedPoint]["5days"].list.map((day) => day.rain["3h"]) : [],
                borderColor: 'white',
                borderWidth: 2,
                yAxisID: 'y1',
            },
        ],
    };

    useLayoutEffect(() => {
        if (selectedCourse) {
            /*// 2DAYS
            axios.get(Endpoints.getWeatherURLFor(selectedCourse.id, 2, null, null))
                .then((response) => set2DaysData(response.data))
                .catch((error) => console.error(error))
            // 5DAYS
            axios.get(Endpoints.getWeatherURLFor(selectedCourse.id, 5, null, null))
                .then((response) => {
                    set5DaysData(response.data)
                    setLabels(response.data.points[selectedPoint]["5days"].list.map((day) => {
                        var timestamp = moment.unix(day.dt)
                        return `${moment(timestamp).tz('Asia/Bangkok').format("DD/MM (HH")} h)`
                    }))
                    setPointsStyle(response.data.points[selectedPoint]["5days"].list.map((day) => getArrow(day.wind.deg, 40, 40)))
                })
                .catch((error) => console.error(error))
            // 12 DAYS
            axios.get(Endpoints.getWeatherURLFor(selectedCourse.id, 12, null, null))
                .then((response) => set12DaysData(response.data))
                .catch((error) => console.error(error))*/
            set2DaysData(days2JSON)
            set5DaysData(days5JSON)
            set12DaysData(days12JSON)
        }
    }, [selectedCourse, selectedPoint])

    return <div className="flex flex-col space-y-8">
        <div className="mt-5 flex flex-row w-full">
            <div className="w-full">
                <Line options={options} data={data} />
            </div>
            <div className="w-full">
                <Chart options={options2} data={data2} />
            </div>
        </div>
        {
            days12Data &&
            <div className="w-full overflow-x-scroll">
                <div className="flex flex-col mb-5">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                            </th>
                                            {
                                                days12Data.points[selectedPoint]['12days'].list.map((day) => <th
                                                    scope="col"
                                                    className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    {moment(moment.unix(day.dt)).tz('Asia/Bangkok').format("DD/MM (HH")} h)
                                                </th>)
                                            }
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        <tr key='icon'>
                                            <td className="px-6 py-4 whitespace-nowrap">

                                            </td>
                                            {
                                                days12Data.points[selectedPoint]['12days'].list.map((day) => <td className="px-6 py-4 whitespace-nowrap text-center">
                                                    <img src={`http://openweathermap.org/img/wn/${day.weather[0].icon}@2x.png`} alt={day.weather[0].description} width="80" height="80" />
                                                </td>)
                                            }
                                        </tr>
                                        <tr key='icon-descr'>
                                            <td className="px-6 py-4 whitespace-nowrap">

                                            </td>
                                            {
                                                days12Data.points[selectedPoint]['12days'].list.map((day) => <td className="px-6 py-4 whitespace-nowrap text-center">
                                                    {day.weather[0].main}
                                                </td>)
                                            }
                                        </tr>
                                        <tr key='temp-max'>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                T Max.
                                            </td>
                                            {
                                                days12Data.points[selectedPoint]['12days'].list.map((day) => <td className="px-6 py-4 whitespace-nowrap text-center">
                                                    {day.temp.max.toFixed(0)} °C
                                                </td>)
                                            }
                                        </tr>
                                        <tr key='temp-min'>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                T Min.
                                            </td>
                                            {
                                                days12Data.points[selectedPoint]['12days'].list.map((day) => <td className="px-6 py-4 whitespace-nowrap text-center">
                                                    {day.temp.min.toFixed(0)} °C
                                                </td>)
                                            }
                                        </tr>
                                        <tr key='wind_speed'>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                Wind speed
                                            </td>
                                            {
                                                days12Data.points[selectedPoint]['12days'].list.map((day) => <td className="px-6 py-4 whitespace-nowrap text-center">
                                                    {convertMStoKmH(day.speed)} km/h
                                                </td>)
                                            }
                                        </tr>
                                        <tr key='wind_deg'>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                Wind degree
                                            </td>
                                            {
                                                days12Data.points[selectedPoint]['12days'].list.map((day) => <td className="px-6 py-4 whitespace-nowrap text-center">
                                                    <div className="flex flex-row place-items-center">
                                                        <img src={getArrow(day.deg, 40, 40).src} alt={day.deg} />
                                                        {windDirection(day.deg)}
                                                    </div>
                                                </td>)
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>
}